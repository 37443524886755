import React from "react"

import { withPassword } from "./withPassword"
import { Image } from "../Image/Image"
import { RichText } from "../RichText/RichText"
import { FormInput, FormError } from "../Styled/Form"
import { PrimaryButton } from "../Styled/Button"
import { Section, StyledContainer, StyledImage, StyledH5, StyledForm, StyledP } from "./PasswordStyles"

interface Props {
  content: any
  data: any
  error: any
  field: any
  handleChange: any
  handleSubmit: any
  locales: any
}

export const Password = withPassword(({ content, data, error, field, handleChange, handleSubmit, locales }: Props) => (
  <>
    <Section inverted={content?.backgroundImage?.asset?.fluid?.src}>
      {content?.backgroundImage?.asset?.fluid?.src && (
        <StyledImage>
          <Image cover={true} src={content.backgroundImage.asset.fluid} alt={content.backgroundImage.alt} />
        </StyledImage>
      )}

      <StyledContainer inverted={content?.backgroundImage?.asset?.fluid?.src}>
        {content?.title && <StyledH5>{content.title}</StyledH5>}

        {content?.content && (
          <StyledP as={`div`}>
            <RichText content={content.content} />
          </StyledP>
        )}

        <StyledForm onSubmit={handleSubmit}>
          <FormInput
            autoFocus
            inverted
            name={`password`}
            onChange={handleChange}
            placeholder={locales.placeholder}
            ref={field}
            type={`password`}
            value={data?.password}
          />
          <PrimaryButton full={`true`} type={`submit`}>
            {locales.submit}
          </PrimaryButton>

          {error && <FormError>{`Invalid password`}</FormError>}
        </StyledForm>
      </StyledContainer>
    </Section>
  </>
))

import tw, { styled } from "twin.macro"

import { Container } from "../Styled/Container"
import { Form } from "../Styled/Form"
import { H5, P } from "../Styled/Text"

export const StyledForm = tw(Form)`mt-3-2`
export const Section = styled.section`
  ${tw`relative flex items-center justify-center w-full-vw h-full-vh overflow-hidden`}
  ${props => (props.inverted ? tw`bg-dark` : ``)}
`
export const StyledContainer = styled(Container)`
  ${tw`relative text-center flex flex-col items-center justify-center`}
  ${props => (props.inverted ? tw`text-light` : tw`text-dark`)}
`
export const StyledImage = styled.div`
  &,
  > * {
    position: absolute !important;
    ${tw`inset-0`}
  }
`
export const StyledH5 = tw(H5)`mb-1-2`
export const StyledP = tw(P)`text-base`
